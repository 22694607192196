<template>
  <v-container>

    <v-row class="mt-4">
      <v-col cols="3" v-if="stats">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1 mt-n2" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.totalDispatchers ? stats.totalDispatchers : 0 }} <br />
                <v-list-item-subtitle>
                  <span class="black--text">Total Dispatchers</span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                      </span>
                    </template>
                    <span>Total Number of Dispatchers</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="3" v-if="stats">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1 mt-n2" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.blockedDispatcher ? stats.blockedDispatcher : 0 }} <br />
                <v-list-item-subtitle>
                  <span class="black--text">Blocked Dispatchers</span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                      </span>
                    </template>
                    <span>Total Number of Blocked Dispatchers</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="3" v-if="stats">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1 mt-n2" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.activeDispatchers ? stats.activeDispatchers : 0 }} <br />
                <v-list-item-subtitle>
                  <span class="black--text">Active Dispatchers</span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                      </span>
                    </template>
                    <span>Total Number of Active Dispatchers</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="3" v-if="stats">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1 mt-n2" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.pendingDispatchers ? stats.pendingDispatchers : 0 }} <br />
                <v-list-item-subtitle>
                  <span class="black--text">Pending Dispatchers</span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                      </span>
                    </template>
                    <span>Total Number of Pending Dispatchers</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row justify="space-between">
      <v-col cols="5">
        <v-btn-toggle v-model="filters.status" color="primary">
          <v-btn text small style="height: 50px" value="All"> All </v-btn>
          <v-btn text small style="height: 50px" value="active"> Active </v-btn>
          <v-btn text small style="height: 50px" value="pending"> Pending </v-btn>
          <v-btn text small style="height: 50px" value="blocked"> Blocked </v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col cols="6" class="mt-3">
        <v-row>
          
          <div style="width: 200px">
            <date-filter :init-date="initDate" @dateChange="onDateChange" />
          </div>
          
          <v-text-field v-model="filters.search" label="Search Dispatchers" solo append-icon="mdi-magnify" style="width: 200px" />

          <v-btn color="primary" class="ml-4" @click="openAddDispatcherDialog" style="width: 95px; height: 47px; margin-left: 5px">
            <v-icon small>mdi-plus</v-icon>
            Add New
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <add-update-dispatcher-dialog @dispatcherAdded="fetchDispatchers" :dispatcherId="selectedDispatcherId" @close="modalAbruptlyClosed" ref="addUpdateDispatcherDialog" />

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="dispatchers"
          :loading="loading"
          :server-items-length="totalItems"
          :options.sync="tableOptions"
          :footer-props="{
            'items-per-page-options': tableOptions.rowsPerPageItems,
          }"
          class="elevation-1"
        >

            <template v-slot:[`item.isVerified`]="{ item }">
              <v-chip class="ma-2" label outlined small :color="getColor(item)">
                {{ item.banned ? "BLOCKED" : (item.isVerified ? "ACTIVE" : "PENDING")
                }} </v-chip>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{ getReadableDate(item.createdAt) }}
            </template>
            
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom v-if="!item.deletedAt">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="viewDispatcher(item._id)">
                    mdi-eye
                  </v-icon>
                </template>
                <span>View</span>
              </v-tooltip>

              <v-tooltip bottom v-if="!item.deletedAt">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="toggleVerification(item._id, item.isVerified)">
                    {{ item.isVerified ? 'mdi-account-check' : 'mdi-account-off' }}
                  </v-icon>
                </template>
                <span>{{ item.isVerified ? 'Disapprove' : 'Approve' }}</span>
              </v-tooltip>

              <v-tooltip bottom v-if="!item.deletedAt">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="toggleBanned(item._id, item.banned)">
                    {{ item.banned ? 'mdi-account-lock' : 'mdi-account' }}
                  </v-icon>
                </template>
                <span>{{ item.banned ? 'Unblock' : 'Block' }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="editDispatcher(item._id)">
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip bottom v-if="!item.deletedAt">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="onDeleteDispatcher(item._id)">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import {
  getDispatchers,
  deleteDispatcher,
  updateDispatcher,
  getDispatcherStats,
} from "@/services/admin";

import DateFilter from "@/components/dateFilter";
import AddUpdateDispatcherDialog from '@/components/addDispatcherForm';
function debounce(func, delay) {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export default {
  components: { DateFilter, AddUpdateDispatcherDialog },
  data() {
    return {
      loading: false,
      dispatchers: [],

      filters: {
        startDate: null,
        endDate: null,
        status: "All",
        search: '',
        order: 'DESC'
      },
      statusOptions: ['All', 'Active', 'Pending', 'Incomplete', 'Blocked', 'Deleted'],

      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'surName' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Status', value: 'isVerified' },
        { text: 'Company Name', value: 'company.name' },
        { text: 'Total Trucks', value: 'company.totalTrucks' },
        { text: 'Total Drivers', value: 'company.totalDrivers' },
        { text: 'Created At', value: 'createdAt' },

        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],

      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        rowsPerPageItems: [10, 30, 50, 100],
      },

      totalItems: 10,

      initDate: [],
      usersStatus: "all",
      stats: null,
      selectedDispatcherId: null

    };
  },
  created() {
    this.fetchDispatchers();
    this.fetchDispatcherStats();
  },
  watch: {
    'tableOptions': {
      handler: 'fetchDispatchers',
      deep: true
    },

    // usersStatus() {
    //   this.filters.status = this.usersStatus
    //   this.fetchDispatchers();
    // },

    'filters.status': {
      handler(newStatus, oldStatus) {
        if (newStatus !== oldStatus) {
          this.fetchDispatchers();
        }
      },
      deep: true
    },

    'filters.search': {
      handler: debounce(async function (newValue) {
        this.fetchDispatchers();
      }, 500),
      immediate: false
    },
  },
  methods: {
    async fetchDispatchers() {
      try {
        this.loading = true;
        const response = await getDispatchers({
          ...this.tableOptions,
          ...this.filters,
          take: this.tableOptions.itemsPerPage,
          status: this.filters.status === 'All' ? null : this.filters.status
        });

        this.dispatchers = response.data.data;
        this.totalItems = response.data.meta.itemCount;
      } catch (error) {
        console.error('Error fetching dispatchers:', error);
      } finally {
        this.loading = false;
      }
    },

    async toggleVerification(dispatcherId, isVerified) {
      try {
        const updatedDispatcher = { isVerified: !isVerified };
        await updateDispatcher(dispatcherId, updatedDispatcher);
        this.fetchDispatchers();
        this.fetchDispatcherStats();
      } catch (error) {
        console.error('Error toggling verification status:', error.message);
      }
    },

    async toggleBanned(dispatcherId, isBanned) {
      try {
        const updatedDispatcher = { banned: !isBanned };
        await updateDispatcher(dispatcherId, updatedDispatcher);
        this.fetchDispatchers();
        this.fetchDispatcherStats();
      } catch (error) {
        console.error('Error toggling banned status:', error.message);
      }
    },

    modalAbruptlyClosed() {
      if (this.selectedDispatcherId) {
        this.selectedDispatcherId = null;
        return
      }
    },

    async fetchDispatcherStats() {
      try {
        // this.stats = {

        //   "totalDispatchers": 0,
        //   "blockedDispatcher": 0,
        //   "activeDispatchers": 0,
        //   "pendingDispatchers": 0,
        // };
        const response = await getDispatcherStats();
        this.stats = response.data;
      } catch (error) {
        console.error("Error fetching dispatcher stats:", error);
      }
    },

    editDispatcher(dispatcherId) {
      this.selectedDispatcherId = dispatcherId;
      this.$refs.addUpdateDispatcherDialog.dialog = true;
    },

    openAddDispatcherDialog() {
      this.$refs.addUpdateDispatcherDialog.dialog = true;
    },

    onDateChange(newValue) {
      // console.log('newValue', newValue);
      if (!newValue || newValue.length !== 2) return;

      // Convert the strings to Date objects
      const startDate = newValue[0] ? new Date(newValue[0]) : null;
      const endDate = newValue[1] ? new Date(newValue[1]) : null;

      // Update the filters with the new start and end dates
      this.filters.startDate = startDate ? startDate.toISOString().split('T')[0] : '';
      this.filters.endDate = endDate ? endDate.toISOString().split('T')[0] : '';

      // Fetch dispatchers with the updated filters
      this.fetchDispatchers();
      this.fetchDispatcherStats();
    },

    async onDeleteDispatcher(dispatcherId) {
      const confirmDelete = window.confirm('Are you sure you want to delete this dispatcher?');

      if (confirmDelete) {
        try {
          await deleteDispatcher(dispatcherId);
          // console.log('Dispatcher deleted successfully');
          this.fetchDispatchers();
          this.fetchDispatcherStats();
        } catch (error) {
          console.error('Error deleting dispatcher:', error.message);
        }
      }
    },

    getColor(item) {
      return item.banned ? "red" : item.isVerified ? "green" : "orange"
    },

    getReadableDate(date) {
      const d = new Date(date);
      return d.toUTCString();
    },

    viewDispatcher(dispatcherId) {
      this.$router.push({ name: 'Dispatcher General Details', params: { id: dispatcherId } });
    },
  },


};
</script>

<style></style>